<template>
  <div>
    <div class="wrap-adjustPoint">
      <div class="wrap-table">
        <a-table
          row-key="RowKey"
          :columns="columnTable"
          :data-source="dataActivityLog"
          :pagination="{
            pageSize: defaultPageSize,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${totalSerial}`,
            pageSizeOptions: pageSizeOptions,
            defaultCurrent: 1,
            total: totalSerial,
            showSizeChanger: true,
            hideOnSinglePage: false,
            onChange: onChangePage,
            onShowSizeChange: onShowSizeChange,
          }"
          :customRow="openDetail"
        >
          <template slot="date" slot-scope="text">
            {{ dateShortFormatUTC(text) }}
          </template>
          <template slot="status" slot-scope="text, record">
            {{ record.success ? lbl['success'] : lbl['fail'] }}
          </template>
        </a-table>
      </div>
    </div>

    <!-- Modal Detail -->
    <a-modal
      v-model="visibleDetail"
      id="modal-detail-log"
      :title="lbl['import-log-dtl-title']"
      :footer="null"
      centered
      class="modal-Detail"
      :class="[currentLocale]"
    >
      <div class="filterContainer nopad mb-2">
        <div :class="['filterMain', showFilterMain ? 'active' : '']">
          <div class="filter" @click="toggle('showFilterMain')">
            {{ lbl['user-permissions-filter'] }}
            <img :src="require('@/assets/images/filter_list.png')" alt="" />
          </div>
          <div class="spaceHover"></div>
          <div class="filterDropdown">
            <div class="filterLeft">
              <div
                :class="['filterList', item === subFilter ? 'active' : '']"
                v-for="item in Object.keys(filter)"
                @click="changeSubFilter(item)"
                :key="item"
              >
                {{ lbl[filter[item].label] }}
                <img
                  :src="require('@/assets/images/navigate_next.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="filterRight">
              <div v-for="item in filter[subFilter].input" :key="item">
                <div class="filterList" @click="selectFilter(item)">
                  <CheckBox
                    :label="item"
                    :valuee="item"
                    :check="filter[subFilter].value"
                    type="filter"
                  />
                </div>
              </div>
            </div>
            <div class="btnFilter">
              <div @click="resetFilter()">
                {{ lbl['user-permissions-reset'] }}
              </div>
              <button @click="getLogPointDetail()">
                {{ lbl['user-permissions-ok'] }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="filterItem"
          v-for="(item, index) in Object.keys(allFilter)"
          :key="'filter' + index"
        >
          {{ allFilter[item]
          }}<img
            @click="removeFilter(item)"
            :src="require('@/assets/images/close.png')"
            alt=""
          />
        </div>
      </div>
      <a-table
        row-key="RowKey"
        :columns="columnsDetail"
        :data-source="dataDetail"
        :pagination="{
          pageSize: defaultPageSizeDetail,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${totalDetail}`,
          pageSizeOptions: pageSizeOptions,
          defaultCurrent: 1,
          total: totalDetail,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onChange: onChangePageDetail,
          onShowSizeChange: onShowSizeChangeDetail,
        }"
      >
        <template slot="status" slot-scope="text">
          <a-tag v-if="text" color="green">
            <span class="d-flex align-center">
              <a-icon type="check-circle" class="mr-1" />
              {{ lbl['import-log-import-success'] }}
            </span>
          </a-tag>
          <a-tag v-else color="red">
            <span class="d-flex align-center">
              <a-icon type="close-circle" class="mr-1" />
              {{ lbl['import-failed'] }}
            </span>
          </a-tag>
        </template>
        <template slot="remark" slot-scope="text, scope">
          <div v-if="scope.Success">
            <span>
              {{ text ? text : '' }}
            </span>
          </div>
          <div v-else>
            <div v-if="scope.Error">
              <span>
                {{ scope.Error }}
              </span>
            </div>
            <div v-else>
              <span
                v-if="
                  text == 'UserId is not found' || text == 'User is not found'
                "
              >
                {{ lbl['user-is-not-found'] }}
              </span>
              <span v-else>
                {{ text ? text : '' }}
              </span>
            </div>
          </div>
        </template>
      </a-table>
      <div class="export-file-container">
        <a-button
          v-if="showExportSuccess"
          class="btn-success"
          @click="getLogPointDetailExport('success')"
        >
          {{ lbl['download-success-user'] }}
        </a-button>
        <a-button
          v-if="showExportFail"
          class="btn-fail"
          @click="getLogPointDetailExport('fail')"
        >
          {{ lbl['download-fail-user'] }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import BzbsImport from '@/core/Import/service/BzbsImport'
import XLSX from 'xlsx'
import _ from 'lodash'
import Locale from '@/helper/locale.js'
import moment from 'moment'
import Account from '@/helper/AccountHelper.js'
import CheckBox from './components/CheckBox.vue'

const columnTable = [
  {
    title: 'File Name',
    dataIndex: 'FileName',
    key: 'import-log-file-name',
    width: '40%',
    align: 'left',
  },
  {
    title: 'Import date',
    dataIndex: 'SuccessDate',
    key: 'import-log-import-date',
    width: '30%',
    align: 'center',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'Success',
    key: 'import-log-import-success',
    align: 'center',
    dataIndex: 'SuccessCount',
  },
  {
    title: 'Fail',
    key: 'import-log-import-fail',
    align: 'center',
    dataIndex: 'FailCount',
  },
]

const columnsDetail = [
  {
    title: 'Import status',
    dataIndex: 'Success',
    key: 'import-log-status-header',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: 'UserID',
    dataIndex: 'UserId',
    key: 'import-log-userid-header',
    width: '26%',
    align: 'left',
  },
  {
    title: 'First Name',
    dataIndex: 'FirstName',
    key: 'import-log-name-header',
    width: '15%',
    align: 'left',
  },
  {
    title: 'Last Name',
    dataIndex: 'LastName',
    key: 'import-log-lastname-header',
    width: '15%',
    align: 'left',
  },
  {
    title: 'Point',
    dataIndex: 'Point',
    key: 'import-search-point',
    width: '15%',
    align: 'center',
  },
  {
    title: 'Total Point/Stamp on hand',
    dataIndex: 'PointOnHand',
    key: 'import-log-point-header',
    width: '15%',
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'Description',
    key: 'import-log-desc-header',
    align: 'left',
  },
  {
    title: 'Remark',
    dataIndex: 'Message',
    key: 'remark',
    align: 'left',
    scopedSlots: {
      customRender: 'remark',
    },
  },
]
export default {
  name: 'AdjustPoint',
  mixins: [Mixin],
  components: { CheckBox },
  data: function () {
    return {
      columnTable,
      columnsDetail,
      dataActivityLog: [],
      dataActivityDetails: [],
      pageSizeSerial: 30,
      currentSerial: 1,
      partionkey: '',
      filename: '',
      totalSerial: null,
      visibleDetail: false,
      currentDetail: 1,
      totalDetail: null,
      pageSizeDetail: 30,
      dataDetail: [],
      dataDetailExport: [],
      showExportSuccess: false,
      showExportFail: false,
      defaultPageSize: 20,
      defaultPageSizeDetail: 20,
      currentSerialSkip: 0,
      currentSerialSkipDetail: 0,
      pageSizeOptions: ['20', '50', '100'],
      currentLocale: Locale.getLocaleShort(),
      rowKey: null,
      subFilter: 'status',
      showFilterMain: false,
      filter: {
        status: {
          value: null,
          input: ['success', 'failed'],
          label: 'import-log-status-header',
          type: 'checkbox',
        },
      },
      allFilter: {},
    }
  },
  created() {
    this.getLogPoint()
    this.setLocale()
  },
  methods: {
    setLocale() {
      _.forEach(this.columnTable, column => {
        column.title = this.lbl[column.key]
      })
      _.forEach(this.columnsDetail, column => {
        column.title = this.lbl[column.key]
      })
    },
    getLogPoint() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getAdjustPointLog(
          this.defaultPageSize,
          this.currentSerialSkip,
        )
          .then(res => {
            console.log('getLogPoint res :: ', res)
            this.handleLoading(false)
            this.totalSerial = res.data.data.Count
            this.dataActivityLog = res.data.data.Serials
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    onChangePage(page) {
      console.log('page :: ', page)
      this.currentSerial = page
      this.currentSerialSkip = (page - 1) * this.defaultPageSize
      this.getLogPoint()
    },
    onShowSizeChange(current, size) {
      this.currentSerial = current
      this.currentSerialSkip = (current - 1) * size
      this.defaultPageSize = size
      this.getLogPoint()
    },
    openDetail(record) {
      return {
        on: {
          click: () => {
            this.partionkey = record.PartitionKey + '|' + record.RowKey
            this.filter.status.value = ''
            this.rowKey = record.RowKey
            this.filename = record.filename
            this.getLogPointDetail()
            this.$nextTick(() => {
              this.visibleDetail = true
            })
          },
        },
      }
    },
    getLogPointDetail() {
      this.handleLoading(true)
      this.showFilterMain = false
      let active = null
      if (this.filter.status.value === 'success') active = true
      if (this.filter.status.value === 'failed') active = false
      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})
      let top = false
      if (active == true || active == false) {
        top = true
        this.currentSerialSkipDetail = 0
      }

      return new Promise(resolve => {
        BzbsImport.getAdjustPointLogDetail(
          top ? 0 : this.defaultPageSizeDetail,
          this.currentSerialSkipDetail,
          this.rowKey,
        )
          .then(res => {
            this.showExportSuccess = false
            this.showExportFail = false
            this.handleLoading(false)
            this.totalDetail = res.data.data.Count
            if (active != null) {
              this.dataDetail = _.filter(res.data.data.Serials, item => {
                if (item.Success == active) return item
              })
              this.totalDetail = this.dataDetail.length
              this.dataDetail.find(ele => {
                if (!ele.Message) {
                  this.showExportSuccess = true
                }
                if (ele.Message) {
                  this.showExportFail = true
                }
              })
            } else {
              this.dataDetail = res.data.data.Serials
              this.dataDetail.find(ele => {
                if (!ele.Message) {
                  this.showExportSuccess = true
                }
                if (ele.Message) {
                  this.showExportFail = true
                }
              })
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    onChangePageDetail(page) {
      this.currentDetail = page
      if (
        this.filter.status.value != 'success' ||
        this.filter.status.value != 'failed'
      ) {
        this.currentSerialSkipDetail = (page - 1) * this.defaultPageSizeDetail
        this.getLogPointDetail()
      }
    },
    onShowSizeChangeDetail(current, size) {
      this.currentDetail = current
      if (
        this.filter.status.value != 'success' ||
        this.filter.status.value != 'failed'
      ) {
        this.currentSerialSkipDetail = (current - 1) * size
        this.defaultPageSizeDetail = size
        this.getLogPointDetail()
      }
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    getLogPointDetailExport(type) {
      this.handleLoading(true)
      this.dataDetailExport = []
      return new Promise(resolve => {
        BzbsImport.getAdjustPointLogDetail(0, 0, this.rowKey)
          .then(res => {
            this.dataDetailExport = res.data.data.Serials
            this.exportFile(type)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    exportFile(type) {
      console.log('exportFile type : ', type)
      Account.bzbsAnalyticTracking(
        'import_point_page',
        'import_point_activity_log_' + type,
        'clickimport_point_activity_log',
        'on click',
      )

      var resultData
      if (type == 'success') {
        resultData = this.dataDetailExport.filter(item => !item.Message)
      } else if (type == 'fail') {
        resultData = this.dataDetailExport.filter(item => item.Message)
      }

      let data = _.map(resultData, item => {
        var user_id = this.lbl['import-log-userid-header']
        var first_name = this.lbl['import-log-name-header']
        var last_name = this.lbl['import-log-lastname-header']
        var point = this.lbl['import-search-point']
        var pointonhand = this.lbl['import-log-point-header']
        var description = this.lbl['import-log-desc-header']
        var remark = this.lbl['remark']

        let message = ''
        if (
          item.Message == 'UserId is not found' ||
          item.Message == 'User is not found'
        ) {
          message = this.lbl['user-is-not-found']
        } else {
          message = item.Message
        }
        return {
          [user_id]: item.UserId ? item.UserId : '',
          [first_name]: item.FirstName ? item.FirstName : '',
          [last_name]: item.LastName ? item.LastName : '',
          [point]: item.Point ? item.Point : '',
          [pointonhand]: item.PointOnHand ? item.PointOnHand : '',
          [description]: item.Description ? item.Description : '',
          [remark]: item.Error ? item.Error : message ? message : '',
        }
      })

      var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
      var wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]
      dataWS['!cols'] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(
        wb,
        'CRMPlus_import_point_' +
          moment(new Date()).format('YYYY-MM-DD') +
          '.xlsx',
      )

      this.handleLoading(false)
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'success' &&
          item === 'success') ||
        (this.filter[this.subFilter].value === 'failed' && item === 'failed')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.getLogPointDetail()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.getLogPointDetail()
    },
  },
}
</script>

<style lang="scss">
@import '../ImportBatchPoint/ActivityLog.scss';
</style>

<style lang="scss">
#modal-detail-log {
  .filterContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .btn-permission {
      width: 157px;
      height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: solid 1px #f9a601;
      background-color: #fff;
      color: #f9a601;
      font-size: 16px;
    }
  }
  .filter,
  .filterItem {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    cursor: pointer;
    color: #616161;
    font-size: 16px;
    font-weight: 500;
    img {
      margin-left: 8px;
    }
  }
  .filterMain {
    position: relative;
    &.active {
      .filterDropdown {
        display: flex;
      }
    }
  }
  .filterDropdown {
    z-index: 3;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    top: 52px;
    position: absolute;
    background-color: #fff;
    display: none;
    padding: 0 0 64px 0;
    font-size: 16px;
    color: #212529;
    .filterLeft {
      width: 250px;
      border-right: 1px solid #e0e0e0;
      @media only screen and (max-width: 460px) {
        width: auto;
      }
    }
    .filterRight {
      width: 250px;
      @media only screen and (max-width: 460px) {
        width: auto;
      }
      &:hover {
        display: block;
      }
      .filterList {
        justify-content: flex-start;
        input {
          cursor: pointer;
        }
        &.input {
          input {
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 5px;
            cursor: auto;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .filterList {
      height: 42px;
      width: 100%;
      padding: 0 24px;
      border-bottom: 1px solid #e0e0e0;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: #fff3e0;
      }
      &:hover {
        background-color: #fff3e0;
      }
      text-align: left;
    }
  }
  .btnFilter {
    width: 100%;
    position: absolute;
    bottom: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: blue;
    div {
      cursor: pointer;
    }
    button {
      align-items: center;
      padding: 6px 18px;
      border-radius: 4px;
      background-color: #ff9800;
      color: #fff;
      border: none;
    }
  }
  .roleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    margin: 24px 0 0 0;
    .roleItem {
      width: 410px;
      @media only screen and (max-width: 550px) {
        width: 100%;
      }
      flex-grow: 1;
      .roleHeader {
        // min-height: 120px;
        border: 1px solid #e0e0e0;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        padding: 16px;
        .headerTab {
          display: flex;
          justify-content: space-between;
          button {
            width: 56px;
            height: 35px;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 5px;
          }
        }
        .textTitle {
          font-size: 20px;
        }
        .chip {
          margin: 0 0 0 8px;
        }
        .roleSubHeader {
          margin-top: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 62px;
          position: relative;
          margin-bottom: 0;
          min-height: 44px;
          &.showmore {
            -webkit-line-clamp: unset;
          }
          span {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #2196f3;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .roleBody {
        height: 449px;
        border: 1px solid #e0e0e0;
        border-radius: 0 0 5px 5px;
        background-color: #fafafa;
        .sortByContainer {
          height: 56px;
          padding: 16px;
          color: #bdbdbd;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e0e0e0;
          .sortFilter {
            &:hover {
              .sortDropdown {
                display: block;
                color: black;
              }
            }
            cursor: pointer;
            position: relative;
            .sortDropdown {
              display: none;
              border-radius: 5px;
              border: 1px solid #e0e0e0;
              width: 124px;
              position: absolute;
              right: 0;
              background-color: white;
              div:first-child {
                border-bottom: 1px solid #e0e0e0;
              }
              div {
                text-align: center;
                padding: 6px;
                cursor: pointer;
                &:hover {
                  background-color: #fff3e0;
                }
              }
            }
          }
        }
        .userListContainer {
          overflow-y: scroll;
          height: calc(100% - 56px);
          /* width */
          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .userList {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            background-color: white;
            gap: 22px;
            padding: 16px;
            border-bottom: 1px solid #e0e0e0;
            @media only screen and (max-width: 460px) {
              padding: 16px 4px;
              flex-wrap: wrap;
              gap: 22px;
              justify-content: center;
            }
            .userStatus {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 64px;
              height: 32px;
              font-weight: 500;
              border-radius: 24px;
              &.active {
                color: #4caf50;
                background-color: #4caf5033;
              }
              &.inactive {
                color: #ff5252;
                background-color: #ff525233;
              }
            }
            .detail {
              text-align: left;
              margin-right: auto;
              margin-left: auto;
              @media only screen and (max-width: 460px) {
                width: 100%;
                text-align: center;
              }
              .userEmail {
                color: #bdbdbd;
                white-space: nowrap;
                width: 145px;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .userName {
                font-size: 16px;
                font-weight: 600;
                margin: 5px auto 0 auto;
                color: #616161;
                white-space: nowrap;
                width: 145px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .action {
              margin-left: auto;
              display: flex;
              gap: 8px;
              @media only screen and (max-width: 460px) {
                margin-left: 0;
              }
              > * {
                width: 56px;
                height: 35px;
                color: #2196f3;
                font-size: 16px;
                font-weight: 500;
                text-decoration: underline;
                border-radius: 5px;
                border: 1px solid #e0e0e0;
                background-color: white;
              }
              .copy {
                border-color: #1e88e51a;
              }
              .edit {
                border-color: #bdbdbd;
              }
            }
          }
        }
      }
    }
  }
}
</style>
